import React, {useEffect} from 'react';
import {getQueryParam} from "../infrastructure/getQueryParam";
import {useLocation} from "@reach/router"
import {navigate} from "gatsby";

const LoginPage = () => {

    useEffect(() => {
        navigate(process.env.APP_HOST + "/login");

    }, []);

    return (<div></div>);
};
export default LoginPage;
